import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  CAT,
  DOG,
  FORMATTED_WELLNESS_PHONE_NUMBER,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';
import { WellnessClubBenefitsOverview } from '@pumpkincare/user';

import styles from './post-checkout-wellness-upsell.css';

const BREED_MAP = {
  [DOG]: {
    wellnessClubValue: '$19.95',
  },
  [CAT]: {
    wellnessClubValue: '$14.95',
  },
};

function PostCheckoutWellnessUpsell({ species }) {
  return (
    <div className={styles.content}>
      <div className={styles.exploreSection}>
        <img
          src='/assets/images/iconImages/wellness_club_logo.svg'
          alt=''
          className={styles.topIcon}
        />

        <span className={classNames(Typography.h4, styles.savingTitle)}>
          Want to save hundreds on your pet’s{' '}
          <span className={styles.title}>routine care</span>?
        </span>

        <Body2 className={styles.savingsInfo}>
          Cash for routine preventive care at the vet & discounts on top pet brands.
          Join Pumpkin Wellness Club for as low as{' '}
          {BREED_MAP[species].wellnessClubValue}/mo!
        </Body2>

        <LegalBody isBold className={styles.exploreLink}>
          Call{' '}
          <a href={WELLNESS_PHONE_NUMBER_LINK} style={{ textDecoration: 'none' }}>
            {FORMATTED_WELLNESS_PHONE_NUMBER}
          </a>{' '}
          to explore plans
        </LegalBody>
      </div>
      <div className={styles.entitlements}>
        <div className={styles.entitlementsBox}>
          <WellnessClubBenefitsOverview species={species} />
        </div>
      </div>
    </div>
  );
}

PostCheckoutWellnessUpsell.defaultProps = {
  species: '',
};

PostCheckoutWellnessUpsell.propTypes = {
  species: PropTypes.oneOf([DOG, CAT]),
};

export default PostCheckoutWellnessUpsell;
